<script setup lang="ts">
  import { onMounted, ref, computed, nextTick, watchEffect } from 'vue';
  import { useUser } from '@/entities/user';
  import { getRandomInteger } from '@/utils/general';
  import UbiSlot from '@/components/ubi/UbiSlot.vue';
  import UbiSmall from '@/components/ubi/UbiSmall.vue';
  import { FeedPeopleRecommendations } from '@/features/user';
  import { FeedPortalRecommendations } from '@/features/portal';
  import { Lootbox } from '@/features/lootbox';
  import type { UserView, ContentView } from 'dfx/edge/edge.did';
  import { useWindowVirtualizer } from '@tanstack/vue-virtual';
  import { useRoute } from 'vue-router';
  import { Loader } from '@/shared/ui/loader';
  import { EmptyPortal } from '@/components/empty-messages';
  import { Post } from '@/features/post';
  import {
    OnboardingStep,
    useFeedStore,
    type TranslatedContentView,
    useDevice,
    useGlobalStore,
  } from '@/shared/model';
  import { storeToRefs } from 'pinia';
  import { OnboardingLootbox, useOnboarding } from '@/features/onboarding';
  import { FeedBanner as GimluckFeedBanner } from '@/features/gimluck';
  import { config } from '@/shared/lib';

  const props = withDefaults(
    defineProps<{
      posts?: ContentView[];
      isLoading?: boolean;
      hideFeedRecommendations?: boolean;
      showPinnedPost?: boolean;
    }>(),
    {
      posts: () => [],
      hideFeedRecommendations: false,
      isLoading: true,
    },
  );

  const emits = defineEmits<{
    (e: 'fetch-next-page'): void;
    (
      e: 'translate-content',
      params: { content: TranslatedContentView; lang: string },
    ): void;
    (e: 'remove-content', content: ContentView): void;
    (e: 'delete-content', content: ContentView): void;
    (e: 'edit-content', content: ContentView): void;
    (
      e: 'pin-content',
      params: {
        content: ContentView;
        isPinned: boolean;
      },
    ): void;
    (
      e: 'pin-content-to-profile',
      params: {
        content: ContentView;
        isPinned: boolean;
      },
    ): void;
    (e: 'set-nsfw', params: { content: ContentView; isNsfw: boolean }): void;
    (e: 'set-rank', params: { content: ContentView; amount: bigint }): void;
    (e: 'goodbye', user: UserView): void;
    (e: 'on-comment', comment: ContentView): void;
    (e: 'on-poll-vote', poll: ContentView): void;
  }>();

  const MIN_ITEM_HEIGHT = 500;
  const route = useRoute();
  const { currentUser, isLoggedIn } = useUser();
  const feedStore = useFeedStore();
  const { savedScrollPosition } = storeToRefs(feedStore);
  const { onboardingState } = useOnboarding();
  const { isDesktop } = useDevice();
  const globalStore = useGlobalStore();
  const { globalLiveData } = storeToRefs(globalStore);

  const canClaimGimluckReward = computed(() => {
    const nextClaimAt = globalLiveData.value?.phantom_claimable_at;
    if (nextClaimAt === BigInt(0)) {
      return true;
    }
    const currentTimeInNanoseconds = BigInt(new Date().getTime()) * 1000000n;
    if (nextClaimAt && currentTimeInNanoseconds >= nextClaimAt) {
      return true;
    }

    return false;
  });

  const randomInt = ref(0);

  const postListRef = ref<HTMLDivElement | null>(null);

  const measureElement = (el: HTMLDivElement) => {
    nextTick(() => {
      if (!el) return;
      windowVirtualizer.value.measureElement(el);
      return undefined;
    });
    return undefined;
  };

  const virtualizerOptions = computed(() => ({
    count: props.posts.length,
    estimateSize: () => MIN_ITEM_HEIGHT,
    scrollMargin: postListRef.value?.offsetTop ?? 0,
  }));
  const windowVirtualizer = useWindowVirtualizer(virtualizerOptions);
  const virtualRows = computed(() => windowVirtualizer.value.getVirtualItems());
  const totalSize = computed(() => windowVirtualizer.value.getTotalSize());

  onMounted(() => {
    randomInt.value = getRandomInteger(9, 26);
  });

  const showReward = (index: number) => {
    return index === randomInt.value;
  };

  watchEffect(() => {
    nextTick(() => {
      const position = savedScrollPosition.value.get(route.fullPath);
      if (position) {
        windowVirtualizer.value.scrollToIndex(position, {
          align: 'end',
        });
      }
    });
  });

  watchEffect(() => {
    const [lastItem] = [...virtualRows.value].reverse();

    if (!lastItem) {
      return;
    }

    if (lastItem.index >= props.posts.length - 1) {
      emits('fetch-next-page');
    }
  });
</script>

<template>
  <div v-if="posts.length > 0" ref="postListRef">
    <div
      class="relative w-full"
      :style="{
        height: `${totalSize}px`,
      }"
    >
      <div
        class="absolute top-0 left-0 w-full"
        :style="{
          transform: `translateY(${
            (virtualRows[0]?.start ?? 0) -
            windowVirtualizer.options.scrollMargin
          }px)`,
        }"
      >
        <div
          v-for="virtualRow in virtualRows"
          :key="virtualRow.key.toString()"
          :data-index="virtualRow.index"
          :ref="measureElement"
        >
          <div class="pt-4 relative">
            <gimluck-feed-banner
              v-if="
                canClaimGimluckReward &&
                config.ENABLE_GIMLUCK &&
                isDesktop &&
                isLoggedIn &&
                virtualRow.index === 0
              "
              class="mb-4"
            />
            <onboarding-lootbox
              v-if="
                virtualRow.index === 0 &&
                onboardingState.currentStep === OnboardingStep.CLAIM_LOOTBOX
              "
            />
            <lootbox class="mb-4" v-if="virtualRow.index === 0" />
            <post
              class="bg-gray-950"
              :post="posts[virtualRow.index]"
              :index="virtualRow.index"
              @delete-content="emits('delete-content', $event)"
              @remove-content="emits('remove-content', $event)"
              @translate-content="emits('translate-content', $event)"
              @edit-content="emits('edit-content', $event)"
              @pin-content="emits('pin-content', $event)"
              @pin-content-to-profile="emits('pin-content-to-profile', $event)"
              @set-nsfw="emits('set-nsfw', $event)"
              @goodbye="emits('goodbye', $event)"
              @set-rank="emits('set-rank', $event)"
              @on-comment="emits('on-comment', $event)"
              @on-poll-vote="emits('on-poll-vote', $event)"
            />
            <feed-people-recommendations
              v-if="!hideFeedRecommendations && virtualRow.index === 0"
            />
            <feed-portal-recommendations
              v-if="!hideFeedRecommendations && virtualRow.index === 2"
            />
            <div
              v-if="showReward(virtualRow.index) && currentUser"
              class="mt-4"
            >
              <ubi-slot>
                <ubi-small />
              </ubi-slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="isLoading" class="relative">
    <div
      class="flex items-center justify-center h-20 my-4 border border-gray-700 border-opacity-70 shadow-lg bg-gray-950 rounded-lg relative"
    >
      <loader variant="rainbow" size="w-8 h-8" border-width="border" />
    </div>
  </div>
  <div
    v-if="!isLoading && posts.length === 0"
    class="p-2 text-lg font-bold text-center text-white bg-gray-950 shadow-lg sm:rounded-xl"
  >
    <empty-portal :slug="(route.params.slug as string)" />
  </div>
</template>
